<template>
  <div style="width: 90%; margin: 5%;">
    <el-form v-if="ruleForm2.external_userid" :model="ruleForm2" :rules="rules2"
      status-icon
      ref="ruleForm2"
      label-position="left"
      label-width="100px"
      class="demo-ruleForm login-page">
      <h3 class="title">系统注册</h3>
      <el-form-item prop="username" label='用户名'>
        <el-input type="text" v-model="ruleForm2.username" auto-complete="off" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item prop="password" label='密码'>
        <el-input type="password" v-model="ruleForm2.password" auto-complete="off" placeholder="密码"></el-input>
      </el-form-item>
      <el-form-item prop="subpasswd" label='确认密码'>
        <el-input type="password" v-model="ruleForm2.subpasswd" auto-complete="off" placeholder="确认密码"></el-input>
      </el-form-item>
      <el-form-item style="width:100%;">
        <el-button type="primary" style="width:100%;" @click="handleSubmit" :loading="logining">确认</el-button>
      </el-form-item>
    </el-form>
    <div v-else>
      <p>请加猛士企业微信，然后再点击注册</p>
      <img style="width: 200px;height: 200px;" src="../assets/wwxqr.jpg"/>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      logining: false,
      ruleForm2: {
        username: '',
        password: '',
        subpasswd: '',
        openid: '',
        external_userid: ''
      },
      rules2: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        subpasswd: [{ required: true, message: '请输入确认密码', trigger: 'blur' }]
      }
    }
  },
  created () { // 创建Vue时就执行
    // alert(this.$route.params.code)
    // let urls
    let params = window.location.href.split('?')[1] // 获取浏览器输入地址
    params = params.replace('code=', '')
    const code = params.split('&')[0]
    this.$axios.get('api/wwx/getuserinfo', {
      params: {
        code: code
      }
    }).then(res => {
      console.log(res)
      if (!res.data.errcode) {
        // alert(res)
        ElMessage({
          message: res.data.name,
          type: 'success'
        })
        this.ruleForm2.external_userid = res.data.external_userid
        this.ruleForm2.openid = res.data.openid
        this.ruleForm2.username = res.data.name
      } else {
        alert(res.data.errmsg)
      }
    })
  },
  mounted () {
  },
  methods: {
    handleSubmit () {
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          if (this.ruleForm2.password !== this.ruleForm2.subpasswd) {
            alert('密码不一致')
            return false
          }
          this.logining = true
          this.$axios.post('api/wwxregister', this.ruleForm2).then(res => {
            console.log(res)
            const temp = res.data
            if (!temp.errcode) {
              ElMessage({
                message: temp.errmsg,
                type: 'success'
              })
              this.$router.push({ path: '/login' })
            } else {
              this.$alert(temp.errmsg, 'info', {
                confirmButtonText: 'ok'
              })
            }
            this.logining = false
          })
        }
      })
    }
  }
}
</script>
